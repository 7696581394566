/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-03-02 17:11:51
 * @LastEditors: hutian
 * @LastEditTime: 2021-03-12 09:15:21
 */
export const columns = [
    {
        title: '角色名称',
        dataIndex: 'name',
        key: 'name',
        /*width:150,*/
        align:'center',
        ellipsis:true
    },
    {
        title: '是否启用',
        dataIndex: 'flagEnable',
        key: 'flagEnable',
        /*width:150,*/
        align:'center',
        ellipsis:true
    },
    {
        title: '创建人',
        dataIndex: 'createUser',
        key: 'createUser',
        /*width:150,*/
        align:'center',
        ellipsis:true
    },
    {
        title: '创建人id',
        dataIndex: 'createUserId',
        key: 'createUserId',
        /*width:150,*/
        align:'center',
        ellipsis:true
    },
    {
        title: '创建时间',
        dataIndex: 'createTime',
        key: 'createTime',
        /*width:150,*/
        align:'center',
        ellipsis:true
    },
    {
        title: '修改人',
        dataIndex: 'modifyUser',
        key: 'modifyUser',
        /*width:150,*/
        align:'center',
        ellipsis:true
    },
    {
        title: '修改人id',
        dataIndex: 'modifyUserId',
        key: 'modifyUserId',
        /*width:150,*/
        align:'center',
        ellipsis:true
    },
    {
        title: '修改时间',
        dataIndex: 'modifyTime',
        key: 'modifyTime',
        /*width:150,*/
        align:'center',
        ellipsis:true
    },
    {
        title: '是否删除',
        dataIndex: 'flagDel',
        key: 'flagDel',
        /*width:150,*/
        align:'center',
        ellipsis:true
    },
    {
        title: '备注',
        dataIndex: 'remark',
        key: 'remark',
        /*width:150,*/
        align:'center',
        ellipsis:true
    },
    {
        title: '数据权限（数据权限范围，0：自定义，1：全部，2：仅自己，3：本部门）',
        dataIndex: 'dataScope',
        key: 'dataScope',
        /*width:150,*/
        align:'center',
        ellipsis:true
    },
    ]
    